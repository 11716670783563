.company-notes {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .add-note-button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #ffffff;
      color: #9855e3;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #c285ff;
      }
    }

    hr {
      flex-grow: 1;
      margin-left: 10px;
      border: none;
      border-top: 1px solid #000;
    }
  }

  .note-input {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    .ql-container {
      height: 70px;
    }

    .post-button {
      align-self: flex-end;
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #9855e3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #c285ff;
      }
    }
  }

  .notes-list {
    margin-top: 16px;
    .note {
      background-color: #f3f3f3b0;
      border-radius: 4px;
      padding: 10px 15px;
      margin-bottom: 10px;
      position: relative;

      .note-content {
        margin-bottom: 10px;
      }

      .note-meta {
        font-size: 0.85em;
        color: #6c757d;
        display: flex;
        justify-content: space-between;
      }

      .save-button {
        display: inline-block;
        padding: 5px 10px;
        margin-top: 10px;
        background-color: #9855e3;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #c285ff;
        }
      }

      .edit-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.2em;
        cursor: pointer;
        background-color: #f3f3f3b0;
      }
    }
  }
}

.ql-tooltip.ql-editing {
  left: 0 !important;
  top: 40px !important;
}
