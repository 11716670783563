.outerLayer {
  background: white;
  box-shadow: 0px 0px 2px 2px #e9e9e9;
  padding: 20px;
  max-width: fit-content;
}
.gridContainer {
  font-family: 'Inter var';
  height: 100%;
  background: white;
  display: grid;
  grid-template-columns: [values] 150px repeat(5, 1fr [companies]);
  overflow: scroll;
  .columnContainer {
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(232, 232, 232, 1);
    height: 100%;
    width: 245px;
    .buttonContainer {
      display: flex;
      height: 50px;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid rgba(232, 232, 232, 1);
    }
  }

  .firstRow {
    height: 200px;
    // border-bottom: 1px solid rgba(232, 232, 232, 1);
  }
  .nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .companyName {
      font-size: 14px;
      font-weight: 600;
      color: #0f98ce;
      margin: 10px 0;
      text-overflow: ellipsis;
      max-width: 200px;
      max-height: 30px;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        cursor: pointer;
      }
      a {
        color: inherit !important;
      }
    }
    .saveToListButton {
      height: 30px;
      width: 100px;
      background: #ffffff;
      color: #484849;
      border-radius: 5px;
      border: 1px solid #484849;
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .sharesSimilarCompaniesWith {
      font-size: 10px;
      background: #f5f8fa;
      padding: 10px;
      width: 80%;
      height: 50px;
      display: flex;
      span {
        align-self: center;
      }
    }
  }
  .firstColumn {
    height: 100%;
    display: flex;
    flex-direction: column;
    letter-spacing: 1px;
  }
  .gridItem,
  .rowName {
    height: 50px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid rgba(232, 232, 232, 1);
    &:not(.gridItem) {
      color: rgba(0, 0, 0, 0.4);
      font-size: 10px;
      font-weight: 600;
    }
  }

  .industry {
    height: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .industryTag {
    height: 130px;
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10px;
    border-top: 1px solid rgba(232, 232, 232, 1);
  }
}

.subContextMenu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
  width: 100%;
  top: 0;
}

.subContextMenu > li,
.subContextMenu > div {
  width: 100%;
}

.newList {
  padding: 24px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 16px;
  width: 519px + 24px * 2;
  background-color: white;
}

.popoverContent {
  min-width: 300px;
}
