// card.scss
.card {
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  min-height: 90px;
  max-width: 232px;
  box-shadow: 0px 4px 6px 0px #EDEDEDCC;
  box-shadow: 0px 2px 4px 0px #EDEDED;
  font-family: Inter;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  
  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .card-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .card-title {
    color: #0192CB;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
  }

  .organization-link {
    color: #0192CB;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
  }

  .card-footer {
    text-align: left;
    padding-top: 16px;
    padding-bottom: 8px;
  }

  h3 {
    color: #0192CB;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    margin: 0;
  }
  
  .card-user {
    font-size: 0.8em;
    text-align: right;
    margin-top: 10px;
  }
}