.latestStats {
  display: flex;
  align-items: baseline;
  margin-top: 5px;
  color: black;
  font-size: 14px;
}

.latestGrowth {
  font-size: 12px;
  margin-left: 6px;
}

.positive {
  color: #0F9960;
}

.negative {
  color: #DB3737;
}
