@import "../../../palette.module";

// border colors
$page-border-color: #BBBEC7;

.page {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.top {
    border-top: solid 1px $page-border-color;
    border-left: solid 1px $page-border-color;
    border-right: solid 1px $page-border-color;
    background-color: $background-color-purple;
    padding: 40px;
    &__listName {
        display: flex;
        gap: 10px;
        font-size: 20px;
        font-weight: 600;
        height: 30px;
        label {
            margin-right: 20px;
            line-height: 30px;
        }
    }
    &__highlights {
        margin-top: 30px;
        display: flex;
        gap: 40px;
    }
}
