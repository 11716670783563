// override some blueprintjs defaults
$black: #000000;
$dark-gray1: #1B1C1C;
$dark-gray2: #323233;
$dark-gray3: #484849;
$dark-gray4: #7D7F80;
$dark-gray5: #B2B2B2;
$light-gray1: #B2B2B2;
$light-gray2: #D1D1D1;
$light-gray3: #EDEDED;
$light-gray4: #F2F2F2;
$light-gray5: #F5F5F5;
$white: #ffffff;
$blue1: #29174B;
$blue2: #552E7B;
$blue3: #9855E3;
$blue4: #A665EF;
$blue5: #C285FF;
$green1: #355852;
$green2: #337161;
$green3: #31816A;
$green4: #539884;
$green5: #77B2A1;
$orange1: #CE4600;
$orange2: #DE4B03;
$orange3: #EF601D;
$orange4: #F1733A;
$orange5: #F68352;
$red1: #B90600;
$red2: #D8100D;
$red3: #EA2A28;
$red4: #ED4A49;
$red5: #F26665;
$pt-divider-black: rgba(#10161A, 0.15);

// background colors
$background-blue: #F5F8FA;
$background-blue-disabled: #E1E8EC;
$background-color-purple: #F4F4FB;

// sidebar
$sidebar-text-color: #666666;
$sidebar-text-color-active: #261338;
$sidebar-item-height: 50px;
$sidebar-expanded-width: 172px;
$sidebar-collapsed-width: 50px;

// company ratings
$love-color: #FBE5EB;
$love-hover-color: #FFD2E5;
$like-color: #FAECD4;
$like-hover-color: #FFE3B3;
$later-color: #E0E0F5;
$later-hover-color: #ADB2F3;
$pass-color: #EDEDED;
$pass-hover-color: #D1D1D1;
$no-rating-color: #FFFFFF;
$no-rating-hover-color: #F2F2F2;

:export {
  white: $white;
  lightGray1: $light-gray1;
  lightGray2: $light-gray2;
  lightGray3: $light-gray3;
  lightGray4: $light-gray4;
  lightGray5: $light-gray5;
  darkGray1: $dark-gray1;
  darkGray2: $dark-gray2;
  darkGray3: $dark-gray3;
  darkGray4: $dark-gray4;
  darkGray5: $dark-gray5;
  black: $black;
  backgroundBlue: $background-blue;
  backgroundBlueDisabled: $background-blue-disabled;
  blue1: $blue1;
  blue2: $blue2;
  blue3: $blue3;
  blue4: $blue4;
  blue5: $blue5;
}
