// pipelineDropDown.scss
.pipeline-dropdown {
  position: relative;
  font-family: 'Arial', sans-serif;
  user-select: none;
  height: 25px;
  display: flex;
  justify-self: center;
  border: 1px solid;
  border-radius: 20px;

  .dropdown-header {
    width: 166px;
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .dropdown-arrow {
      margin-left: 8px;
    }
    .selected-option {
      display: flex;
      align-items: center;
    }
  }

  .dropdown-list {
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 4px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    border-radius: 0px !important;
    overflow: hidden;
    z-index: 20;
  }

  .dropdown-item {
    width: 158px;
    height: 25px;
    margin: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #f0f0f0;
    border-radius: 4px;

    &.selected:before {
      display: block;
      font-size: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.selected {
      font-weight: bold;
      &:before {
        content: '';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .checkmark {
    padding-right: 10px;
  }

  .dropdown-item.selected:before {
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .checkmark {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .label {
    font-size: 10px;
  }
}
