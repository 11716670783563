.newsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-height: 500px;
  overflow-y: auto;
  gap: 0px;
  border-radius: 8px;
}

@media (min-width: 1024px) {
  .newsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.newsItemContainer {
  display: flex;
  background: white;
  padding: 20px 10px;
  cursor: pointer;
}

.newsImageSmall {
  height: 50px;
  object-fit: cover;
}

.newsTextContainer {
  display: flex;
  flex-direction: column;
  margin: 8px;
}
.fallbackImageContainer {
  background: rgb(234, 233, 233);
  padding: 5px 10px;
  border-radius: 5px;
}

.newsImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid rgba(72, 72, 73, 0.2);
  // aspect-ratio: 1 / 1;
}

.imageContainer {
  margin: 8px;
}

.newsItemHeading {
  display: flex;
  font-weight: bold;
  flex-direction: column;
}

.tag {
  margin: 0 5px 0 15px;
}

.newsSummaryCollapsed,
.newsSummaryExpanded {
  display: -webkit-box;
  font-weight: 400;
  color: #000;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  transition: max-height 0.5s ease-in-out, -webkit-line-clamp 0.5s ease-in-out;
  max-height: 4.2em; // Approximate height for 2 lines
  overflow: hidden;
  text-overflow: ellipsis;

  &.newsSummaryExpanded {
    -webkit-line-clamp: unset;
    max-height: 100vh; // Allow full expansion
  }

  &:not(.newsSummaryExpanded) {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.filterContainer {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

.filter {
  margin-right: 5px;
  // background: rgb(217, 217, 217);
  border-radius: 5px;
  padding: 0px 5px 5px;
}
.newsContainer {
  margin: 16px;
  flex-grow: 1;
  // max-width: 75%;
}

.select {
  border-radius: 8px;
  background: #ededed;
}

.newsPublishedDate {
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

.articleTitle {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.clickableLink {
  width: fit-content;
  overflow: hidden;
  color: #0192cb;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.alignRight {
  margin-left: auto;
}

.companyName {
  margin-left: 10px;
}

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.summaryContainer {
  display: flex;
  span {
    align-self: flex-end;
  }
}

.newsCaretIconDown {
  &:hover {
    transform: scale(1.3);
    transition: transform 0.3s ease-in-out;
  }
}

.newsCaretIconUp {
  transform: rotate(180deg);
  &:hover {
    transform: rotate(180deg) scale(1.3);
    transition: transform 0.3s ease-in-out;
  }
}
