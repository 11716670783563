@import '../../palette.module';

/* SideBar */
.nav,
.navCollapsed {
  flex-shrink: 0;
  background: $background-blue;
  display: flex;
  flex-direction: column;
  transition: 0.1s;
  height: 100%;
  padding: 5px 4px 5px 5px;
  gap: 10px;
  box-shadow: 0px 4px 8px rgba(178, 178, 178, 0.3), 0px 2px 4px rgba(178, 178, 178, 0.3);
  z-index: 11 !important;
}

.nav {
  width: $sidebar-expanded-width;
}

.navCollapsed {
  width: $sidebar-collapsed-width;
  .navlinkIsActive {
    height: 35px;
    width: 35px;
  }
}

.ul {
  list-style-type: none;
  padding: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  gap: 10px;
}

.navlink,
.navlinkIsActive {
  display: block;
  border-radius: 3px;

  &:hover {
    color: $blue3;
    text-decoration: none !important;
  }
}

.navlink {
  color: $dark-gray3;

  &:hover {
    background-color: rgba($blue3, 0.08);
  }

  &:active {
    background-color: rgba($blue3, 0.15);
    color: $blue3;
  }
}

.navlinkIsActive {
  background-color: rgba($blue3, 0.15);
  color: $blue3;
}

.active {
  height: calc(#{$sidebar-item-height} - 10px);
}

/* Page Section */

.page {
  flex-shrink: 0;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
}

/* Login */

.login {
  height: 100%;
  width: 100%;
  background: $background-blue;
  display: flex;
  align-items: center;
}

.login > div {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.input {
  margin-top: 20px;
}

.login_button {
  margin-top: 25px;
  width: 150px;
}

.forgot_password_button {
  margin-left: auto;
  margin-top: -10px;
  margin-bottom: -20px;
}

.forgot_password {
  top: 0;
  margin: 15vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}

.forgot_password {
  left: calc(50vw - 400px);
  width: 800px;
  height: 300px;
}

.reset_password {
  width: 300px;
}

.reset_password > .input {
  margin-top: 10px;
}

/* Explanation */

.explanation {
  display: flex;
  align-items: flex-start;

  p {
    margin-bottom: 20px;
  }

  p:last-child {
    margin: 0;
  }
}

.explanationIcon {
  margin: 0 10px 0 0;
}

.nameAndLogo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0px 10px;

  .blueLink > a {
    color: #0192cb;
  }
}

.nameAndLogo > a > span > img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.blueLink {
  display: inline-block;
  // max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.icon {
  display: flex;
  align-items: center;
}
