// // styles.module.scss

.scrollable_BH {
  display: flex;
  white-space: nowrap;
  gap: 8px;
  padding-bottom: 8px;
  overflow-x: auto;
  scrollbar-width: thin;
}

.companies_BH {
  border-radius: 16px;
  max-width: 100%;
  margin: 8px 16px;
}

.companies__header_BH {
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.companies__list_BH {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 10px 0;
  scroll-snap-type: x mandatory;
  align-items: stretch;
  margin-top: 16px;
}

.companies__item_BH {
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  scroll-snap-align: start;
  min-height: 98px;
  min-width: 284px;
  gap: 16px;
}

.logoWrapper_BH {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.companies__logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
}

.companies__info_BH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.companies__name {
  text-decoration: none;
  color: black;
  font-size: 0.9rem;
}

.companies__tags_BH {
  display: flex;
  gap: 6px;
  color: #666;
  font-size: 0.875rem;
}

.companies__growth_metric_BH {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  color: #555;
  margin-left: 60px;
}

.scrollable {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #f0f0f0 #f5f8fa;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }
}

.noCompaniesMessage {
  color: #999;
  text-align: center;
  padding: 20px;
}

.companyLink {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: black;
  }
}

.companies__tag_BH {
  background-color: #f3f3f3;
  border: 1px solid #333;
  color: #333;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.6rem;
  font-weight: 400;
}
