.DetailBlockWidget {
  width: 100%;
  height: 80px;
  padding: 20px;
}

.DetailBlockLabel {
  font-size: 90%;
  color: #999999;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}

.DetailBlockValue {
  font-size: 130%;
  line-height: 1.6em;
  /*font-weight: lighter;*/
  background-color: transparent !important;
  cursor: default !important;
  padding: 0 !important;
  overflow: hidden;
  color: #000000 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.DetailBlockValueContentPopover {
  max-width: 350px;
  padding: 20px;
}

.DetailBlockIcons {
  display: flex;
  gap: 8px;
}
