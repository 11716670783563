.root {
  display: flex;
  padding: 8px 16px;
  width: 300px;
  align-items: center;
  gap: 16px;
  background-color: #FCFCFC;
  text-decoration: none !important;

  &:hover {
    background-color: #E4E9EC;
  }

  &:active {
    background-color: #D5DADE;
  }
}
