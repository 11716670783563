@import "~normalize.css";
@import "inter";

* {
  font-family: 'Inter var', sans-serif;
}

@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine.scss";
@import "ag-grid-customizations";
@import "palette.module";
@import "blueprintjs-fix";
@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "blueprintjs-customizations";

html, body, #root {
  height: 100%;
}

.rating {
  &__label {
    display: inline-block;
    line-height: 20px;
    height: 20px;
    padding: 0 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
  }

  &__love {
    background-color: $love-color;
  }

  &__like {
    background-color: $like-color;
  }

  &__later {
    background-color: $later-color;
  }

  &__pass {
    background-color: $pass-color;
  }

  &__no_rating {
    background-color: $no-rating-color;
  }
}

a.rating, button.rating {
  color: #000;
  background-color: #fff;

  &__active {
    color: #000 !important;
    background-color: #ECEFF1 !important;
    border: solid 1px #DCDCDC;

    &__love {
      span {
        color: #000 !important;
      }

      .bp3-icon {
        color: $love-color !important;
      }
    }

    &__like {
      span {
        color: #000 !important;
      }

      .bp3-icon {
        color: $like-color !important;
      }
    }

    &__later {
      span {
        color: #000 !important;
      }

      .bp3-icon {
        color: $later-color !important;
      }
    }

    &__pass {
      span {
        color: #000 !important;
      }

      .bp3-icon {
        color: $pass-color !important;
      }
    }
  }
}

.lineClamp1 {
  -webkit-line-clamp: 1;
}

.lineClamp2 {
  -webkit-line-clamp: 2;
}

.lineClamp3 {
  -webkit-line-clamp: 3;
}

.lineClamp4 {
  -webkit-line-clamp: 4;
}

.lineClamp5 {
  -webkit-line-clamp: 5;
}

.lineClamp6 {
  -webkit-line-clamp: 6;
}

.lineClamp7 {
  -webkit-line-clamp: 7;
}

.w-190 {
  width: 190px;
}

.caption {
  text-transform: uppercase;
  font-size: 10px;
  color: RGBA(0, 0, 0, 0.4);
  letter-spacing: 1px;
  font-weight: 600;
}

// global CSS variables
:root {
  --left-sidebar-width: 50px;
  --right-sidebar-width: 50px;
}

.key-contacts-grid {
  display: flex;
  gap: 16px;
  width: auto;
}

.key-contacts-grid-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
