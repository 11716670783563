.page {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  width: calc(100% - var(--left-sidebar-width));
  padding-left: 8px;
}

.tabMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-width: 500px;
  width: 450px;
}

.subContextMenu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
  width: 100%;
}

.subContextMenu > li,
.subContextMenu > div {
  width: 100%;
}

.subContextMenuTitle {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-weight: 600;
  line-height: 1;
}

.notFound {
  display: flex;
  align-items: center;
  height: 100%;
}

.notFoundMessage {
  width: 100%;
  text-align: center;
}

/* Toggle Button */

.toggleButton {
  width: 50%;
  min-width: 150px;
}

.greyBg {
  background: rgba(245, 248, 250, 1);
  padding-left: 0px !important;
}
