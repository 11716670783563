.companies {
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  // margin: 16px;
  min-width: 450px;
  background-color: #fff;
}

@media (max-width: 1024px) {
  .companies {
    min-width: 200px;
  }
}

.company-name-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companies__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.companies__header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.see-more {
  font-size: 0.7rem;
  background-color: #f5f8fa;
  width: 100%;
  border: none;
  border-radius: 4px;
  outline: none;

  padding: 12px 24px;

  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: #e2e6ea;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.companies__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.companies__item {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.companies__info {
  flex: 1;
}

.companies__logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
  border: 1px solid rgba(72, 72, 73, 0.2);
}

.companies__name {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  margin-right: 6px;
}

.companies__name a {
  text-decoration: none;
  color: black;
}

.companies__tags {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  align-items: center;
}

.companies__tag {
  background-color: #dfe0ff;
  color: #333;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.6rem;
  font-weight: 700;
}

.founded_on_date_tag {
  background-color: #f3f3f3;
  border: 1px solid #333;
  color: #333;
  font-size: 0.6rem;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 16px;
}

.companies__tag--industry {
  background-color: #f3f3f3;
  border: 1px solid #333;
  color: #333;
  font-size: 0.6rem;
  font-weight: 700;
}

.companies__description {
  font-size: 0.9rem;
  color: #555;
}

.companies__score {
  font-size: 0.7rem;
  font-weight: 600;
  color: #333;
}

.header {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.companies__growth_metric {
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
  font-weight: 400;
  color: #333;
}

.companies__growth_metric_icon {
  color: #5a5a5a;
}

.circularScore {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(#9455e3 0%, #5453df calc(var(--score) * 1%), #f0f1fe calc(var(--score) * 1%));
  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 0;
  }
}

.scoreText {
  position: relative;
  font-size: 0.7rem;
  font-weight: bold;
  color: #000;
}

.companies__growth_metric_item {
  display: flex;
  align-items: flex-end;
  gap: 6px;
  justify-content: flex-end;
  margin-bottom: 4px;
}

@media (max-width: 768px) {
  .companies {
    padding: 10px;
    max-width: 100%;
  }

  .companies__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .companies__item {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
  }

  .company-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .companies__logo {
    margin-bottom: 8px;
  }

  .companies__name {
    font-size: 1rem;
  }

  .companies__score,
  .companies__growth_metric {
    font-size: 0.9rem;
  }

  .companies__name,
  .companies__tag {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch; // Limit to 10 characters
  }

  .companies__description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50ch; // Limit to 10 characters
  }

  .companies__tags {
    flex-wrap: wrap; // Allow tags to wrap within the container
    align-items: center;
  }

  .companies__description {
    width: 100%; // Ensure description takes full width of the card
    overflow: hidden; // Hide overflow content
    white-space: normal; // Allow text to wrap normally
    text-overflow: clip; // Remove ellipsis
  }
}

@media (max-width: 480px) {
  .companies {
    padding: 8px;
  }

  .companies__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .companies__item {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
  }

  .company-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .companies__header h2 {
    font-size: 1rem;
  }

  .see-more {
    font-size: 0.7rem;
    background-color: #f8f9fa;

    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;

    padding: 12px 24px;

    color: #333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    transition: background-color 0.2s ease;

    &:hover {
      background-color: #e2e6ea;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .companies__name {
    font-size: 0.9rem;
  }

  .companies__description {
    font-size: 0.7rem;
  }

  .companies__score,
  .companies__growth_metric {
    font-size: 0.8rem;
  }

  .companies__name,
  .companies__tag {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10ch; // Limit to 10 characters
    align-items: center;
  }

  .companies__description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 30ch; // Limit to 10 characters
  }

  .companies__tags {
    flex-wrap: wrap;
    align-items: center;
  }

  .companies__description {
    width: 100%; // Ensure description takes full width of the card
    overflow: hidden; // Hide overflow content
    white-space: normal; // Allow text to wrap normally
    text-overflow: clip; // Remove ellipsis
  }
}

.company-link {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: black;
  }
}

.newlyDiscoveredCompanies {
  font-size: 0.7rem;
  margin-left: auto;
  color: #999999;
}

.company-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scrollable {
  max-height: 600px;
  overflow-y: auto;
  padding-right: 14px;
  scrollbar-width: thin;
  scrollbar-color: #f0f0f0 #fff;
}

/* Custom scrollbar styles */
.scrollable::-webkit-scrollbar {
  width: 18px;
}

.scrollable::-webkit-scrollbar-track {
  border-radius: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #d9d9d9; /* Thumb color */
  border-radius: 8px;
  border: 2px solid #f0f1fe;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #cacaca;
}

.fullWidthContainer {
  width: 100%;
}
