.panel {
  display: grid;
  grid-template-columns: 210px 210px 422px repeat(2, fit-content(100%));
  align-items: stretch;
  justify-items: stretch;

  & > * {
    display: flex;
    //border-right: 1px solid #cbcbcb;
    padding: 7px 10px;
  }

  & > div {
    align-items: start;
  }

  & > h6 {
    align-items: center;
    background-color: #E9E7FF;
    font-weight: 700;
  }

  & > div:nth-child(5n-1),
  & > div:nth-child(5n) {
    justify-content: center;
  }

  & > div:nth-child(5n-1) {
    margin-top: 5px;
  }

  & > div:nth-child(5n) {
    margin-top: 2px;
  }

  //& > *:nth-child(5n) {
  //  border-right-width: 0;
  //}

  //& > *:nth-child(-n+5) {
  //  border-bottom: 1px solid #cbcbcb;
  //  padding-top: 7px;
  //  padding-bottom: 7px;
  //}

  //& > *:nth-child(5n+6),
  //& > *:nth-child(5n+7),
  //& > *:nth-child(5n+8),
  //& > *:nth-child(5n+9),
  //& > *:nth-child(5n+10) {
  //  background-color: transparent;
  //}
  //
  //& > *:nth-child(10n+6),
  //& > *:nth-child(10n+7),
  //& > *:nth-child(10n+8),
  //& > *:nth-child(10n+9),
  //& > *:nth-child(10n+10) {
  //  background-color: #faf6ff;
  //}

  & > h6 {
    margin-bottom: 0;
  }
}
