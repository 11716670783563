@import "../palette.module";

.rightBar {
  background-color: #FFFFFF !important;
  padding: 7px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: min-content;
  height: 100%;
  align-items: stretch;
}

.buttonStyle {
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 10px;
  text-align: center;
  padding: 5px;
  
  &:not(:hover):not(:active):not(:global(.bp3-active)) > span {
    color: $dark-gray3 !important;
  }
}
