.tag {
  background: #EDEDED;
  display: inline-block;
  color: #000;
  position: relative;
  padding: 2px;
  border-radius: 16px;
  width: 30px;
  text-decoration: none;
  text-align: center;
  font-size: x-small;
}