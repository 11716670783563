.newTag {
  border-radius: 10px;
  background-color: rgb(224,235,233);
  color:rgb(50,129,106);
  padding: 5px;
  text-align: center;
  font-size: 10px;
}

.newTagBox {
  width: 45px;
}