// addToPipelinePopup.scss

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.add-button {
  display: flex;
  justify-content: flex-end;
  button {
    margin: 6px;
  }
}
