@import '../../../palette.module';

.divider {
  color: RGB(72, 72, 73);
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    cursor: text !important;
    background: inherit !important;
  }
}

.menuItem {
  color: black !important;
  
  :global .bp3-icon-blank {
    border: 1px solid #b3b3b2;
    border-radius: 3px;
  }
  :global .bp3-icon-tick {
    background: $blue3;
  }
  :global .bp3-control {
    margin-bottom: 0 !important;
  }
}

.menuItemActive {
  background: #d0d1d0 !important;
}

.itemText {
  position: relative;
}

.buttonBlocker {
  position: absolute;
  width: 17px;
  height: 15px;
  background: rgb(229,229,228);
  z-index: 999;
  right: 5px;
}
