.autosuggestContainer {
  background: transparent;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    padding: 10px 0px;
    border: none;
    li {
      border: none !important;
    }
  }
}

.suggestionContainer,
.closedSuggestionDropdown {
  border-top: 1px solid transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  background: white;
  width: 100%;
  top: 70px;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: auto;
  z-index: 100;
  &:not(.suggestionContainer) {
    background: transparent !important;
    color: transparent !important;
    box-shadow: none !important;
  }
}

.input {
  display: flex;
  width: 716px;
  min-width: 360px;
  max-width: 720px;
  align-items: center;
  background: white;
  // width: 100%;
  padding: 10px 20px;
  border: 1px solid rgba(72, 72, 73, 0.2);
  // min-width: 20rem;
  border-radius: 50px;
  height: 64px;
  position: relative;
  padding-left: 50px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    margin-top: 20px;
  }
}
.inputWithValue {
  background: white;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  min-width: 20rem;
  &:focus {
    outline: none;
  }
  &::placeholder {
    margin-top: 20px;
  }
}

.renderSuggestion {
  background: transparent;
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 70px;
  position: relative;
  &:hover {
    background: rgb(235, 235, 235);
    cursor: pointer;
  }
  img {
    margin-right: 20px;
  }
  .companyDomain {
    font-size: small;
    color: gray;
    margin-left: 10px;
  }
}

.tags {
  display: inline-block;
  padding: 0 8px;
  border-radius: 10px;
  background-color: rgb(202, 202, 202);
  color: #333;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease;
  margin: 4px 8px 8px 0;
  height: 20px;
  max-width: fit-content;
  line-height: 20px;

  &:hover {
    background-color: rgb(223, 225, 245);
  }
}

.flexCol {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.flexRow {
  display: flex;
}

.cutText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  display: block;
}

.loadingSpinner {
  position: sticky;
  bottom: 2%;
  margin-left: 50%;
}

.noCompaniesReturned {
  padding: 20px;
  align-items: center;
}
