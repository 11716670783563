// column.scss

.column {
  background-color: #f4f5f7;
  border-radius: 8px;
  padding: 0 10px;
  min-width: 236px;
  max-width: 236px;
  margin: 0 8px;
  min-height: 100px;
  transition: min-width 0.3s ease-in-out;
  font-family: Inter;
  padding-bottom: 20px;
  margin-bottom: 20px;
  overflow-y: auto;

  &.collapsed {
    min-width: 40px;
    width: 60px;
    padding: 10px 2px;

    .column-header {
      flex-direction: column;

      h2 {
        display: block;
        writing-mode: vertical-lr;
        transform: rotate(0deg);
        text-align: center;
        white-space: normal;
        overflow: visible;
        margin: 2px 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        padding: 4px;
      }

      .status-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 4px 0px;
      }

      .counter {
        padding: 4px;
      }

      .collapse-toggle {
        margin: 2px 0;
      }

      .card-count {
        margin: 10px 0px;
        color: #7D7F80;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
      }
    }

    .column-cards {
      display: none;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    span.collapse-toggle {
      margin: 0px 4px;
    }

    .status-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0px 4px;
      padding: 4px;
    }

    h2 {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      padding: 4px;
    }
  }

  .column-cards {
    min-height: 100px;
  }

  .card-count {
    color: #7D7F80;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
  }
}