// companyStatusDropDown.scss

.custom-menu-item:hover {
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important;
}

.custom-menu-item.bp3-menu-item:hover {
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important;
}