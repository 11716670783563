.flexContainer {
  display: flex;
  background: white;
  padding: 15px;
}

.teamActivityAvatar {
  width: 30px;
  height: 30px;
  // border-radius: 50%;
  // background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.flexColContainer {
  display: flex;
  flex-direction: column;
  .boldInfo {
    display: flex;
    font-weight: bold;
  }
}

.header {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.teamActivityHolder {
  background: white;
  padding: 24px;
  border-radius: 8px;
}

.partitionedActivityContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
}

.timePartitionHeader {
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.plainText {
  a {
    color: #0192cb;
  }
}

.activityItemsContainer {
  max-height: 640px;
  overflow: auto;
  border-radius: 8px;
}
