.board {
  display: flex;
  // padding: 20px;
  overflow-x: auto;
  height: calc(100vh - 40px);
}

.pipeline-title {
  margin: 0px 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  color: #484849;
}

h2.pipeline-title {
  font-size: 23px;
  font-weight: 600;
}

.logo {
  height: 20px;
  width: 20px;
  margin: 0 10px;
}
