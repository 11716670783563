.pill {
  display: inline-block;
  // padding: 8px;
  border-radius: 50px;
  // background-color: rgba(222, 225, 255, 1);
  color: #333;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 8px 8px 8px 0;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 248, 250, 0.6);
  border: 1px solid grey;
  position: relative;

  &:hover {
    background-color: rgb(223, 225, 245);
  }
}

.absoluteButton {
  border: none;
  background: transparent;
  margin-left: 10px;
}
