.news-list {
  overflow-y: scroll;
}

.news-list-box {
  display: flex;
  flex-direction: column;
}

.news-meta {
  color: #b1b1b1;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list li {
  list-style-type: none;
  margin-bottom: 8px;
  padding-left: 10px;
  cursor: pointer;
  background-color: #fcfcfc;
  border-radius: 4px;
  line-height: 16pt;
}

.list li:hover {
  background-color: #f0f0f0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.popup {
  z-index: 10;
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vh;
  max-width: 90vw;
  overflow-y: auto;
}

.popup h2 {
  margin-top: 0;
}

.popup article {
  margin-bottom: 1em;
}

.popup span {
  margin-right: 4px;
  font-weight: 600;
}

.close-button {
  margin-top: 10px;
}

.close-button .bp3-button {
  background-color: #9855e3 !important;
  color: white !important;
}

.close-button .bp3-button:hover {
  background-color: #c285ff !important;
}

.articleContent {
  white-space: pre-line;
}

.articleTitle {
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides any overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis at the end if text overflows */
  width: 100%; /* Adjust the width as needed */
  display: block;
}

.articleUrl {
  margin-left: 50px;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}