.page {
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    height: calc(100% - 50px);
    overflow: auto;
    /*border: solid 1px #bbbec7;*/
    align-items: flex-start;
}

.acceptedOn {
    color: #999;
    font-size: 10px;
    margin-top: 4px;
}

.clearButton {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}
