.root {
  background-color: #fafafa;
  padding: 10px 20px 20px 20px;

  & > label {
    font-weight: bold;
    margin-bottom: 10px !important;
  }
}

.weightContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #d9d9d9;

  & > *:last-child {
    margin-left: auto;
  }
}

.weight {
  display: flex;
  gap: 8px;
}
