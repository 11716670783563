.insightBody {
  display: flex;
  flex-wrap: wrap;
}

.insightCard {
  display: flex;
  margin: 10px;
}

.insightCard img {
  max-width: 100px;
  max-height: 100%;
}

li {
  line-height: 24pt;
}

.industryCardTitle {
  font-size: 20px;
  font-weight: 550;
}