.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  & h3 {
    font-size: 14px;
    margin: 0 0 10px 0;
  }
}

.header {
  background-color: #dfd9ed;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.profileName {
  background-color: #fafafa;
  padding: 10px 20px 20px 20px;

  & > label {
    font-weight: bold;
    margin-bottom: 10px !important;
  }
}

.button {
  display: flex;

  & > *:first-child {
    margin-left: auto;
    margin-right: 10px;
  }
}