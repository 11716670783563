@import './palette.module';

:root {
  //header options
  --ag-border-color: RGBA(0, 0, 0, 0.15);
  --ag-header-background-color: #f5f8fa;
  --ag-header-column-resize-handle-color: RGBA(48, 48, 49, 0.15);
  --ag-header-foreground-color: #484849;

  //row options
  --ag-odd-row-background-color: white;
  --ag-row-hover-color: #f5f5f5;
  --ag-selected-row-background-color: #f2f2f2;

  //misc options
  --ag-range-selection-border-color: #9855e3;
  --ag-font-size: 14px;
  --ag-grid-custom-header-padding: 16px;

  //checkbox options
  --ag-checkbox-checked-color: #9855e3;
  --ag-checkbox-indeterminate-color: #9855e3;
}

// removes cell border, on the checkbox cell
.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.no-active-border.ag-cell-focus {
  border: none !important;
  outline: none !important;
}

// checkbox styling start
.ag-theme-alpine .ag-checkbox-input-wrapper {
  &:hover {
    background-color: $light-gray3;
  }

  &:active {
    background-color: $light-gray2;
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 2px 0.01rem rgba($blue1, 0.7);
  }

  &:focus-within {
    box-shadow: 0 0 2px 0.01rem rgba($blue1, 0.7);
  }
}

.ag-checked.ag-checkbox-input-wrapper {
  &:hover {
    box-shadow: 0 0 2px 0.01rem rgba($blue2, 0.4);
    box-shadow: none;
  }

  &:active {
    color: $blue1;
    box-shadow: 0 0 2px 0.01rem rgba($blue1, 0.7);
  }

  &:focus {
    box-shadow: 0 0 2px 0.01rem rgba($blue1, 0.7);
  }

  &:focus-within {
    box-shadow: 0 0 2px 0.01rem rgba($blue1, 0.7);
  }
}
// checkbox styling end

.ag-theme-alpine {
  .ag-header-row {
    font-size: 14px;
    font-weight: 700;
    color: #000;
  }

  .ag-header-row:hover {
    background-color: #e1e8ec;
  }

  .ag-header-group-cell {
    padding-left: var(--ag-grid-custom-header-padding);
    padding-top: var(--ag-grid-custom-header-padding);
    padding-bottom: var(--ag-grid-custom-header-padding);
  }

  .ag-header-icon:hover {
    color: #787373;
  }

  .ag-header-cell {
    padding-left: var(--ag-grid-custom-header-padding);
    padding-top: var(--ag-grid-custom-header-padding);
    padding-bottom: 15px;
    font-weight: 500;
  }
  .ag-cell {
    padding-left: 12px;
  }

  .ag-header {
    border-bottom: none;
  }

  .ag-status-bar {
    border-top: none !important;
    box-shadow: 0px -2px 4px 0px rgb(178 178 178 / 20%) !important;
    z-index: 1;
  }
}

.ag-cell {
  display: flex;
  align-items: center;
  line-height: 21px !important;

  // show ellipsis on overflow in text-only cells
  & > span,
  &.text-3-lines > div {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 3 !important;
  }
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

.ag-filter {
  max-width: 500px;
}

.ag-tooltip {
  max-width: 500px;
  font-size: 110%;
  line-height: 21px !important;

  & ul {
    margin: 0;
    padding-left: 20px;
    padding-right: 5px;
  }
}

// hide drag-and-drop handles in sidebar > columns
.ag-icon-grip.ag-drag-handle {
  display: none;
}

// hide wrapper ag-grid border
.ag-root-wrapper {
  border: none !important;
}

// right sidebar
.ag-side-bar-right,
.ag-column-panel-column-select {
  border: none !important;
}

.ag-column-panel {
  border-right: 1px solid var(--ag-border-color, #babfc7) !important;
}

.ag-side-buttons {
  display: none;
  width: auto !important;
  padding-top: 0 !important;
}

.ag-side-bar {
  clip-path: inset(0px 0px 0px -100px);
}

.bp3-elevation-2 {
  box-shadow: 0px 2px 4px rgba(178, 178, 178, 0.3), 0px 8px 16px rgba(178, 178, 178, 0.2) !important;
}

// hide sidebar leftover border
.ag-tool-panel-wrapper {
  border-right: 0 !important;
}

.ag-tool-panel-wrapper {
  min-width: 330px;
}

.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell {
  border-top: none;
}

// hides the resize handle in the header
.ag-header-cell-resize {
  visibility: hidden;
}
// reveals the resize handle when hovered in the header
.ag-header-cell:hover .ag-header-cell-resize,
.ag-header-group-cell:hover .ag-header-cell-resize {
  visibility: visible;
}

//header font weight
.ag-header-cell-text {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

// bottom right corner grid spacer color same as scroll bar
.ag-horizontal-right-spacer {
  background: #f9f9f9;
}

// .passedCompany {
//   background: #D1CCCB !important;
// }
.ag-sort-order {
  display: none;
}