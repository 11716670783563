.chatContainer {
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 40px;
  margin: 40px;
  overflow: auto;
}

.chatInput,
.freshChatInput {
  display: flex;
  width: 716px;
  min-width: 360px;
  max-width: 720px;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border: 1px solid rgba(72, 72, 73, 0.2);
  align-self: center;
  border-radius: 50px;
  height: 64px;
  padding-left: 60px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-weight: 600;
  }

  // &:not(.freshChatInput) {
  //   position: absolute;
  //   bottom: 3rem;
  // }
}

.bottomInput {
  margin-top: auto;
  width: 716px;
  align-self: center;
}
.userMessage {
  overflow-wrap: anywhere;
  width: fit-content;
  padding: 16px 24px;
  border-radius: 10px;
  background: white;
}

.responseMessage {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.flexEnd {
  display: flex;
  justify-content: end;
  margin-bottom: 40px;
}

.chatIcon {
  height: 25px;
}

.iconContainer {
  padding: 5px;
  width: 35px;
  margin-top: 0.67em;
  margin-right: 16px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.inputHeading {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(90deg, #a460dc 0%, #d95180 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
}

.chatSessionList {
  overflow: auto;
  height: inherit;
}

.header {
  white-space: nowrap;
  overflow: hidden;
}

.chatPageContainer {
  display: flex;
  overflow: auto;
}

.chatHistoryContainer {
  height: 100vh;
  padding: 50px;
  // border: 1px solid black;
}
.chatHistoryList {
  position: relative;
  background: white;
  padding: 24px;
  height: 95%;
  border-radius: 8px;
  width: 400px;
  transition: width 0.5s ease-in-out;
}

.shrunk {
  width: 72px;
  height: 72px;
  background: transparent;
}

.relativeContainer {
  position: relative;
}

.toggleViewButton {
  display: flex;
  justify-content: space-between;

  > button {
    border: none;
    height: 40px;
    padding: 12px;
    cursor: pointer;
  }
}

.absoluteElement {
  position: absolute;
  top: 20px;
  left: 20px;
}

.absoluteButton {
  position: absolute;
  top: 7px;
  right: 10px;
  height: 50px;
  width: 50px;
  border-radius: 24px;
  background: linear-gradient(180deg, #9d55db 0%, #da517e 100%);
  border: none;
  color: white;
  cursor: pointer;
}

.chatHistoryItem {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
  line-height: normal;
  cursor: pointer;
}

.timePartitionHeader {
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.newChatButton {
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #f5f8fa;
  margin-bottom: 24px;
  width: 140px;
  cursor: pointer;
}
