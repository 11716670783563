$color0: #E05175;
$color1: #FCA203;
$color2: #4D52DE;
$color3: #484849;
$color4: #666666;

:export {
  color0: $color0;
  color1: $color1;
  color2: $color2;
  color3: $color3;
  color4: $color4;
}

.color-0 {
  background-color: #FBE5EB !important;
  color: $color0 !important;

  &:hover {
    background-color: #FFD2E5 !important;
    color: $color0 !important;
  }
}

.color-1 {
  background-color: #FAECD4 !important;
  color: $color1 !important;

  &:hover {
    background-color: #FFE3B3 !important;
    color: $color1 !important;
  }
}

.color-2 {
  background-color: #E0E0F5 !important;
  color: $color2 !important;

  &:hover {
    background-color: #ADB2F3 !important;
    color: $color2 !important;
  }
}

.color-3 {
  background-color: #EDEDED !important;
  color: $color3 !important;

  &:hover {
    background-color: #D1D1D1 !important;
    color: $color3 !important;
  }
}

.color-4 {
  background-color: #FFFFFF !important;
  color: $color4 !important;

  &:hover {
    background-color: #F5F5F5 !important;
    color: $color4 !important;
  }
}
