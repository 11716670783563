.searchComponentContainer,
.noBg {
  padding: 65px;
  position: relative;
  border-radius: 20px;
  min-width: 700px;
  width: -webkit-fill-available;
  // height: 500px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  &:not(.noBg) {
    background: url('/resources/images/gray-lines.svg') round,
      linear-gradient(90deg, rgba(211, 246, 255, 0.8) 0%, rgba(229, 216, 255, 0.8) 50.5%, rgba(228, 229, 255, 0.8) 100%);
  }
}

.presetsContainer {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

@media (max-width: 1536px) {
  .presetsContainer {
    width: 90%;
  }
}

@media (max-width: 1387px) {
  .presetsContainer {
    width: 100%;
  }
}

.searchButton {
  display: flex;
  width: 354px;
  height: 40px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(90deg, #a155d5 0%, #d95181 100%);
  border: none;
  color: white;
  margin-top: 1rem;
  cursor: pointer;
}

.autosuggestOverlayRight {
  position: absolute;
  right: 0;
  top: 0;
}
.autosuggestOverlayLeft {
  position: absolute;
  left: 0;
  top: 0;
}
.relativeContainer {
  position: relative;
}

.absoluteElement {
  position: absolute;
  top: 20px;
  left: 20px;
}

.absoluteButton {
  position: absolute;
  top: 7px;
  right: 10px;
  height: 50px;
  width: 50px;
  border-radius: 24px;
  background: linear-gradient(180deg, #9d55db 0%, #da517e 100%);
  border: none;
  color: white;
  cursor: pointer;
}

.chatIcon {
  height: 25px;
}
